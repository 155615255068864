import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Service4Container from "../containers/Service4";

interface IProps {}

const Service4 = (props: IProps) => {
  return (
    <Layout>
      <Service4Container />
    </Layout>
  );
};

export default Service4;

export const Head = () => (
  <SEO
    title={`Супер маркет, хүнс хүргэлт`}
    description={`Супер маркет, хүнс хүргэлт`}
  />
);
