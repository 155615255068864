import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { BsCheck2 } from "react-icons/bs";
import anime from "../utils/anime";

interface IProps {}

const Service4Container = (props: IProps) => {
  const list = [
    {
      title: "Үйлчилгээгээ сонгох",
      desc: "Дэлгүүр, худалдан авах зүйлсийн жагсаалтаа гаргаарай.",
    },
    {
      title: "Захиалгаа хийх",
      desc: "Захиалгаа хүргүүлэх цаг болон хаягаа тодорхой бичиж хүссэн үедээ бараагаа цуглуулаарай.",
    },
    {
      title: "Таалагдсан бол давтан үйлчлүүлэх",
      desc: "Хоол унд, гэр ахуйн бараагаа цуглуулах гэж олон цаг бүү зарцуул.",
    },
  ];

  const priceList = [
    {
      title: "Энгийн хүргэлт",
      duration: "30кг хүртэлх ачаа 24 цаг",
      price: "₮10,000 - ₮15,000",
      items: ["А бүс - ₮10,000", "Б бүс - ₮15,000", "Б бүс - ₮15,000"],
    },
    {
      title: "Түргэн хүргэлт",
      duration: "30кг хүртэлх ачаа 3 цаг",
      price: "₮3,000 - ₮5,000 нэмэгдэнэ",
      items: ["А бүс - ₮10,000", "Б бүс - ₮15,000", "Б бүс - ₮15,000"],
    },
    {
      title: "Том ачаа хүргэлт",
      duration: "30кг-аас 1тн хүртэлх ачаа",
      price: "₮50,000 - ₮80,000",
      items: ["Хүргэх", "Буулгах", "Зөөх, оруулах"],
    },
  ];

  return (
    <div className="relative overflow-hidden">
      <div className="absolute hidden lg:block top-0 right-0 bottom-0">
        <StaticImage
          src="../images/b4.png"
          alt={"Urtuu"}
          placeholder="none"
          quality={100}
          layout="fixed"
          height={578}
        />
      </div>

      <div
        className="absolute hidden lg:block top-0 left-0 bottom-0 h-[578px] w-[120%] right-0"
        style={{
          background:
            "linear-gradient(90deg, #FFFFFF 15.26%, #FFFFFF 35.56%, rgba(255, 255, 255, 0) 48.36%)",
        }}
      ></div>

      <div className="mx-auto max-w-7xl px-8">
        <div className="flex flex-col md:flex-row lg:min-h-[600px] items-center w-full relative">
          <div className="w-ful flex flex-col justify-center">
            <div className="block lg:hidden relative">
              <StaticImage
                src="../images/b4.png"
                alt={"Urtuu"}
                placeholder="none"
                quality={100}
              />
               <div className="absolute transform scale-[70%] z-[1] top-0 left-0">
                  <StaticImage
                    src="../images/soon.png"
                    alt={"Coming soon"}
                    placeholder="none"
                    height={110}
                    quality={100}
                  />
                </div>
            </div>
            <div className="text-left relative flex-col justify-center items-start">
              <div
                className="font-bold my-5 lg:mt-0 text-[21px] lg:text-[38px] relative  inline-block uppercase mb-5"
                {...anime("fade-right", 600)}
              >
                супермаркет,
                <br /> хүнс хүргэлт
                <div className="absolute hidden lg:block transform scale-[45%] lg:scale-100  translate-x-[50px] lg:translate-x-20 translate-y-4 lg:-translate-y-5 -right-1/2 -top-1/2">
                  <StaticImage
                    src="../images/soon.png"
                    alt={"Coming soon"}
                    placeholder="none"
                    height={110}
                    quality={100}
                  />
                </div>
              </div>
              <p
                className="text-[21px] text-justify lg:text-left p-0 w-full mb-2 text-[#1E1E1E] lg:w-1/3"
                {...anime("fade-right", 600, 300)}
              >
                Өргөн хэрэглээний бараа, хүнсний бүтээгдэхүүнийг нэг дороос
                хүргэлттэй нь хамт авах боломжтой үйлчилгээ.
              </p>
              {/* <button
                className="h-[85px] text-[21px] px-10 font-bold text-c1 items-center justify-center flex border-[2px] rounded-full border-c1"
                {...anime("fade-right", 600, 600)}
                onClick={() => {}}
              >
                Захиалах
              </button> */}
            </div>
          </div>
        </div>

        <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center m-5 mb-10">
          Үйлчилгээ авах дараалал
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          {list?.map((l, i) => (
            <div
              {...anime("fade-up", 500, 300 * i)}
              key={`f-lis-${i}`}
              className="bg-[#F8F8F8] rounded-[20px] flex flex-col  space-y-4 justify-center items-center p-10"
            >
              <div className="w-[100px] h-[100px] text-5xl font-bold bg-c1 rounded-full flex items-center justify-center text-white">
                {i + 1}
              </div>
              <h3 className="font-bold text-[21px] text-center">{l.title}</h3>
              <p className="text-[#1E1E1E] text-center text-[18px]">{l.desc}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center my-20 space-x-10">
          <a href="https://apps.apple.com/mn/app/urtuu/id6446311351">
            <StaticImage
              src="../images/appstore.png"
              alt={"Urtuu App - App Store"}
              placeholder="none"
              height={68}
              quality={100}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=mn.urtuu.app">
            <StaticImage
              src="../images/playstore.png"
              alt={"Urtuu App - Play Store"}
              placeholder="none"
              height={68}
              quality={100}
            />
          </a>
        </div>
      </div>

      <div className="bg-c2 py-[50px]">
        <div className="mx-auto max-w-7xl px-8">
          <div className="grid items-center grid-cols-1 lg:grid-cols-2 grid-rows-1 w-full mx-auto lg:w-[80%]">
            <div>
              <StaticImage
                src="../images/c5.png"
                alt={"Urtuu"}
                placeholder="none"
                quality={100}
                // layout="fixed"
                // height={400}
                layout="fullWidth"
              />
            </div>
            <div className="p-10">
              <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                Хүргэлтийн ажилчид
              </h1>
              <p className="text-[18px] leading-[40px]">
                Таны захиалсан бараа бүтээгдэхүүнийг ямар нэгэн эвдрэл
                гэмтэлгүй, найдвартай, түргэн шуурхай хүргэх чадварлаг хамт
                олныг та сонгоорой.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-8 mb-20">
        <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center my-20">
          Үнийн мэдээлэл
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 w-full justify-center mx-auto gap-10">
          {priceList?.map((p, i) => (
            <div className="bg-white shadow-xl p-10 flex flex-col items-center border border-gray-100 rounded-xl">
              <StaticImage
                src="../images/f8.png"
                alt={"Urtuu"}
                placeholder="none"
                quality={100}
                // layout="fixed"
                height={85}
                layout="fixed"
              />
              <div className="w-full pt-10">
                <span className="text-[18px]">{p.duration}</span>
                <h3 className="font-bold text-[21px] text-left leading-[30px] my-2">
                  {p.title}
                </h3>
                <span className="text-[18px]">{p?.price}</span>
                <ul className="mt-5 space-y-6">
                  {p?.items?.map((_, i) => (
                    <li
                      className="flex items-center space-x-2 mb-2 text-[18px]"
                      key={`b1-${i}`}
                    >
                      <BsCheck2 size={21} /> <span>{_}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service4Container;
